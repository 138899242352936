import {mapGetters} from 'vuex';

export default {
  name:'page-list-mixins',
  provide() {
    return {
      pageListThis: this,
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'token', 'baseData', 'height']),
    detailID() {
      return this.tableSelectItem.length === 1 ? this.tableSelectItem[0].id : null;
    },
    resultHeight() {
      if (this.tableHeight) return this.tableHeight;
      if (this.$router.currentRoute.params.id) {
        if (this.mid) {
          return this.height - 390;
        } else {
          return this.height - 300;
        }
      } else {
        if (this.mid) {
          return this.height - 300;
        } else {
          return null
        }
      }
    }

  },
  props: {
    tableData: {type: Array, default: () => []},
    midData: {type: Array, default: () => []},
    parentId: {type: [Number, String], default: null},
    mid: {type: [Number, String], default: null},
    tableHeight: {type: [Number, String], default: null},
    header: {type: Boolean, default: true},
    isPage: {type: Boolean, default: true},
    detailInner: {type: Boolean, default: false},
    footer: {type: Boolean, default: true},
    multiColumn: {type: Boolean, default: true},
  },
  watch: {
    'detailID': function (data) {
      if (!data && this.detailFlag) {
        this.detailFlag = false;
        return false;
      }
      this.drawerLoading = true;
      window.setTimeout(() => {
        this.drawerLoading = false;
      },)
    },
  },
  data() {
    return {
      tableList: [],
      tableSelectItem: [],
      modShow: false,
      tabName: '1',
      detailFlag: false,
      loading: false,
      drawerLoading: false,
      params: {},
    }
  },
  methods: {
    async getList(nextType) {
      this.tableSelectItem = [];
      this.$refs['table'].selectAll(false);
      await this.$refs['table'].getList(nextType);
    },
    add() {
      this.modShow = true;
      this.tableSelectItem = [];
      this.$refs['table'].selectAll(false);
    },
    checkItem(selection) {
      this.tableSelectItem = selection;
    },
    delItem(url, params,method='$del') {
      this.$Modal.confirm({
        title: '提示',
        content: '确认要删除这 ' + this.tableSelectItem.length + ' 条记录么?一旦删除将无法恢复!',
        okText: '删除',
        cancelText: '取消',
        onOk: () => {
          this[method](url, params ? params : {id: this.tableSelectItem[0].id}).then(res => {
            if (res.code === 200) {
              this.$Notice.success({
                title: '提示',
                desc: '操作成功！'
              });
              this.getList();
            }
          })
        }
      })
    }
  }
}
